import StateItem from './StatsItem';
import productimage from '../../assets/icons/icon-BagShop.svg';
import salaryImage from '../../assets/icons/icon-Dollar.svg';
import productimageDisabled from '../../assets/icons/icon-BagShopDisabled.svg';
import salaryImageDisabled from '../../assets/icons/icon-DollarDisabled.svg';
import infoImg from '../../assets/Info.svg';

import SubscribeDetails from '../SubscribeDetails';
export default function StatsContainer({ data, isLoadingProfile }) {
  const showStatus = 'false';
  const defaultStats = [
    {
      title: 'عدد المنتجات',
      count: data?.store?.analytics?.web.products_count,
      countDesc: 'منتج',
      icon: productimage,
      color: 'text-[#91268F]',
      border: 'border-[#91268F]',
      bcg: 'bg-[#F5EBF5]',
      desc: 'إجمالي عدد المنتجات التي تم إضافتها للسلة',
    },
    {
      title: 'القيمة النقدية',
      count: data?.store?.analytics?.web.products_price,
      countDesc: 'ر.س',
      icon: salaryImage,
      color: 'text-[#F0B213]',
      bcg: 'bg-[#FFF9E9]',
      border: 'border-[#F0B213]',
      desc: 'قيمة المنتجات التي أضيفت للسلة',
    },
    // {
    //   title: 'القيمة النقدية',
    //   count: data?.store?.analytics.products_price,
    //   countDesc: 'ر.س',
    //   icon: salaryImage2,
    //   color: 'text-[#00A79E]',
    //   border: 'border-[#00A79E]',
    //   bcg: 'bg-[#D6F1EF]',
    //   desc: 'متوسط قيمة السلة',
    // },
  ];

  const appStats = [
    {
      title: 'عدد المنتجات',
      count: data?.store?.analytics?.app.products_count,
      countDesc: 'منتج',
      icon: `${
        data?.store?.analytics?.app.unlocked
          ? productimage
          : productimageDisabled
      }`,
      color: `${
        data?.store?.analytics?.app.unlocked
          ? 'text-[#91268F]'
          : 'text-[#B0B0B0]'
      }`,
      border: `${
        data?.store?.analytics?.app.unlocked
          ? 'border-[#91268F]'
          : 'border-[#D6D6D6]'
      }`,
      bcg: `${
        data?.store?.analytics?.app.unlocked ? 'bg-[#F5EBF5]' : 'bg-[#fff]'
      }`,
      desc: 'إجمالي عدد المنتجات التي تم إضافتها للسلة',
    },
    {
      title: 'القيمة النقدية',
      count: data?.store?.analytics?.app.products_price,
      countDesc: 'ر.س',
      icon: `${
        data?.store?.analytics?.app.unlocked ? salaryImage : salaryImageDisabled
      }`,

      color: `${
        data?.store?.analytics?.app.unlocked
          ? 'text-[#F0B213]'
          : 'text-[#B0B0B0]'
      }`,
      bcg: `${
        data?.store?.analytics?.app.unlocked ? 'bg-[#FFF9E9]' : 'bg-[#fff]'
      }`,
      border: `${
        data?.store?.analytics?.app.unlocked
          ? 'border-[#F0B213]'
          : 'border-[#D6D6D6]'
      }`,
      desc: 'قيمة المنتجات التي أضيفت للسلة',
    },
  ];
  const totalStats = [
    {
      title: 'عدد المنتجات',
      count: data?.store?.analytics?.total_products_count,
      countDesc: 'منتج',
      icon: productimage,
      color: 'text-[#91268F]',
      border: 'border-[#91268F]',
      bcg: 'bg-[#F5EBF5]',
      desc: 'إجمالي عدد المنتجات التي تم إضافتها للسلة',
    },
    {
      title: 'القيمة النقدية',
      count: data?.store?.analytics?.total_products_price,
      countDesc: 'ر.س',
      icon: salaryImage,
      color: 'text-[#F0B213]',
      bcg: 'bg-[#FFF9E9]',
      border: 'border-[#F0B213]',
      desc: 'قيمة المنتجات التي أضيفت للسلة',
    },
  ];
  return (
    <div>
      <h3 className='text-xl sm:text-2xl font-bold'>لوحة التحكم</h3>
      <h3 className='text-primary text-xs sm:text-sm  font-light'>
        تفاصيل الاشتراك
      </h3>
      <SubscribeDetails data={data} />
      {/* web analatics */}
      <div className='mt-5 sm:mt-12'>
        <h2 className='text-primary font-bold text-[24px] mb-2 sm:mb-4'>
          احصائيات الويب
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-3'>
          {defaultStats.map((item, index) => (
            <StateItem
              key={index}
              {...item}
              isLoadingProfile={isLoadingProfile}
            />
          ))}
        </div>
      </div>
      {/* app analatics */}
      <div className='mt-5 sm:mt-12'>
        <h2 className='text-primary font-bold text-[24px] mb-2 sm:mb-4'>
          احصائيات التطبيق
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-3'>
          {appStats.map((item, index) => (
            <StateItem
              key={index}
              {...item}
              isLoadingProfile={isLoadingProfile}
              showStatus={data?.store.analytics.app.unlocked ? 'true' : 'false'}
            />
          ))}
        </div>
      </div>
      {/* total stats */}
      <div className='mt-5 sm:mt-12'>
        <div className='flex items-center gap-2 mb-4 sm:mb-4'>
          <h2 className='text-primary font-bold text-[24px] '>الإجمالي</h2>
          <img src={infoImg} alt='info' />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-3'>
          {totalStats.map((item, index) => (
            <StateItem
              key={index}
              {...item}
              isLoadingProfile={isLoadingProfile}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
