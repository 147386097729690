import React from 'react';
import phone from '../assets/Icon - Phone.svg';
import mail from '../assets/Icon - Message-5.svg';
import address from '../assets/Icon - map-marker.svg';
import logoCompany from '../assets/icons/LogoAppsBunches.png';
export default function Footer({ addStyle = 'sm:my-5' }) {
  const currentYear = new Date().getFullYear();
  return (
    <div className={`flex justify-between gap-4 flex-wrap ${addStyle}  my-3 `}>
      <div className='flex items-center flex-wrap gap-5'>
        <span className='text-xs text-[#666]'>
          جميع الحقوق محفوظة لـ تاسك اب - {currentYear}
        </span>
        <a
          href='https://bit.ly/3utKGEf'
          target='_blank'
          className='flex gap-1 items-center'
        >
          <span className='text-xs text-[#666]'>تطوير</span>
          <div className='w-[150px] h-[56px]'>
            <img
              src={logoCompany}
              alt='logoCompany'
              className='w-full h-full object-contain'
            />
          </div>
        </a>
      </div>
      <div className='flex gap-3 flex-wrap'>
        <div className='flex gap-2 items-center'>
          <img src={address} alt='address' />
          <span className='text-xs text-[#666]'>
            طريق الامام سعود بن عبد العزيز (مخرج 9), الرياض{' '}
          </span>
        </div>
        <div className='flex gap-2 items-center'>
          <img src={mail} alt='mail' />
          <span className='text-xs text-[#666]'>
            <a href='mailto:Apps@AppsBunches.com'>Apps@AppsBunches.com </a>
          </span>
        </div>
        <div className='flex gap-2 items-center'>
          <img src={phone} alt='phone' />
          <span className='text-xs text-[#666] ' dir='ltr'>
            <a href='tel:+966532331339'>+966 532 331 339</a>
          </span>
        </div>
      </div>
    </div>
  );
}
