import React, { useState } from 'react';
import blockImg from '../../assets/blockImg.svg';
export default function StateItem({
  title,
  count,
  icon,
  color,
  bcg,
  border,
  countDesc,
  desc,
  isLoadingProfile,
  showStatus,
}) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = () => {
    if (showStatus === 'false') setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    if (showStatus === 'false') setIsTooltipVisible(false);
  };
  return (
    <div className='relative'>
      {isLoadingProfile ? (
        <div className='h-[176px] w-full  bg-gray-300 animate-pulse rounded-xl'></div>
      ) : (
        <div
          className={`flex flex-col gap-3 items-center rounded-lg py-4 md:py-6 px-3 md:px-6 ${bcg} border-2 ${border} relative overflow-hidden `}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          {showStatus === 'false' && (
            <>
              <div class='triangle-up cur'></div>
              <img
                src={blockImg}
                alt='blokkimg'
                className='absolute left-[12px] top-[18px]'
              />
            </>
          )}

          <div className='flex items-center gap-2'>
            <img src={icon} alt='icon' />
            <h2 className='text-grayColor text-[18px]'>{title}</h2>
          </div>
          <div className='flex gap-1'>
            <span className={`text-3xl sm:text-5xl ${color} font-bold`}>
              {count}
            </span>
            <h2 className={`${color} text-lg self-end`}>{countDesc}</h2>
          </div>
          <div className='flex items-center gap-1'>
            <h3 className={`${color} font-bold text-sm`}>{desc}</h3>
          </div>
        </div>
      )}
      {isTooltipVisible && (
        <div
          className='absolute -left-[55px] -top-[64px] bg-base-100 max-w-[200px] text-[#707070] px-2 py-2 rounded-md shadow-lg z-10 text-center'
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <div className='tooltip-content relative'>
            عليك{' '}
            <a
              class='font-bold underline underline-offset-4'
              href='https://web.zid.sa/market/app/3083'
              target='_blank'
            >
              ترقية الباقة
            </a>{' '}
            لتفعيل هذة الخاصية
            <div className='tooltip-arrow' />
          </div>
        </div>
      )}
    </div>
  );
}
