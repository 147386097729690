import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { FaSearch } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { useDispatch } from "react-redux";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

const ProductModal = ({ open, handleToggle, myProducts, setMyProducts }) => {
  const modalClass = cn({
    "modal modal-middle": true,
    "modal-open": open,
  });
  const dispatch = useDispatch();
  const [allProducts, setAllProducts] = useState([]);
  const [tempProductsAdded, setTempProductsAdded] = useState(myProducts);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const fetchProducts = async () => {
    const { data } = await customFetch(
      `/get-products?name=${search}&page=${page}`
    );
    setPage((prevPage) => prevPage + 1);
    return data.data.products;
  };

  const { isLoading: isLoadingFetchProducts, data: products } = useQuery({
    queryKey: ["allProducts", search],
    queryFn: fetchProducts,
    onError: (error) => {
      checkForUnauthorizedResponse(error, dispatch);
    },
    enabled: open,
  });

  const sortSelectedProducts = (products, selectedProducts) => {
    const unselected = products.filter((item) => {
      return !selectedProducts.some((selected) => selected.id === item.id);
    });
    const selected = selectedProducts.filter((selected) => {
      return products.some((item) => item.id === selected.id);
    });
    const missingSelected = selectedProducts.filter((selected) => {
      return !products.some((item) => item.id === selected.id);
    });

    return [...selected, ...missingSelected, ...unselected];
  };

  useMemo(() => {
    if (products) {
      setAllProducts(sortSelectedProducts(products, tempProductsAdded));
    }
  }, [products, tempProductsAdded]);

  useEffect(() => {
    const scrollableDiv = document.getElementsByClassName("products-list")[0];

    const handleScroll = async () => {
      const { scrollTop, clientHeight, scrollHeight } = scrollableDiv;
      const hasScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 5;
      if (hasScrolledToBottom && !search) {
        try {
          const newProducts = await fetchProducts();
          setAllProducts((prevProducts) => {
            return [...prevProducts, ...newProducts].filter(
              (item, index, self) => {
                return index === self.findIndex((t) => t.id === item.id);
              }
            );
          });
        } catch (error) {
          console.error("Error fetching new products:", error);
        }
      }
    };

    scrollableDiv.addEventListener("scroll", handleScroll);
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  const handleChange = (product) => {
    const isSelected = tempProductsAdded.some((item) => item.id === product.id);
    let updatedSelectedProducts;
    if (isSelected) {
      updatedSelectedProducts = tempProductsAdded.filter(
        (item) => item.id !== product.id
      );
    } else {
      updatedSelectedProducts = [...tempProductsAdded, product];
    }
    setTempProductsAdded(updatedSelectedProducts);
    setAllProducts(sortSelectedProducts(allProducts, updatedSelectedProducts));
  };

  const handleAddToSala = () => {
    if (tempProductsAdded.length === 0) {
      // toast.error('من فضلك أضف بعض المنتجات');
      return;
    }
    setMyProducts(tempProductsAdded);
    handleToggle();
  };

  return (
    <dialog id="product_modal" className={`${modalClass}`}>
      <div className="modal-box max-w-[82rem] h-[82rem] p-0 overflow-hidden">
        <form
          method="dialog"
          className="flex justify-between items-center bg-[#F5F7F7] py-2 px-5"
        >
          <h3 className="text-base sm:text-lg font-semibold ">
            اضافة منتج في واجهة السلة
          </h3>
          <button onClick={handleToggle}>
            <IoMdClose className="font-bold text-black text-lg" />
          </button>
        </form>
        <div className="flex flex-col gap-3 mt-3 sm:mt-5 px-5 sm:px-6">
          {/* search */}
          <div className="relative">
            <div className="form-control">
              <input
                type="text"
                placeholder="بحث"
                className="input input-bordered input-sm sm:input-md rounded-3xl"
                name="search"
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setSearch(e.target.value);
                }}
              />
            </div>
            <FaSearch
              type="submit"
              className="text-lg text-primary absolute top-2 sm:top-4 left-5"
            />
          </div>
          {tempProductsAdded.length === 4 && (
            <div className="flex gap-2 items-center mt-3 text-[#EAA900] text-base sm:text-lg font-bold">
              <IoAlertCircleOutline className="text-2xl" />
              <span className="">
                لقد وصلت للحد الاقصى لإضافة المنتجات في السلة
              </span>
            </div>
          )}
          {/* items */}
          <div className="flex flex-col gap-3 pb-20 h-[75vh] overflow-y-auto products-list">
            {isLoadingFetchProducts ? (
              <span className="loading loading-dots loading-lg mx-auto block text-primary"></span>
            ) : allProducts?.length === 0 ? (
              <h3 className="text-center text-lg font-semibold">
                لا يوجد منتجات
              </h3>
            ) : (
              allProducts?.map((product) => {
                const isSelected = tempProductsAdded.some(
                  (item) => item.id === product.id
                );
                return (
                  <label
                    className={`custom-radio flex justify-between py-2 sm:py-4 cursor-pointer rounded-lg px-3 border-[1px] border-gray-200`}
                    htmlFor={product.id}
                    key={product.id}
                  >
                    <div className="flex gap-8">
                      <input
                        type="checkbox"
                        name="radio-1"
                        className="radio radio-primary self-center"
                        id={product.id}
                        value={product}
                        checked={isSelected}
                        onClick={() => {
                          handleChange(product);
                        }}
                        readOnly
                        disabled={!isSelected && tempProductsAdded.length === 4}
                      />
                      <div className="flex items-center gap-8">
                        <div className="w-[120px] h-[120px]">
                          <img
                            src={product?.main_image}
                            alt="product"
                            className="w-full h-full object-contain rounded-2xl"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <h4 className="text-lg font-normal">
                            {product.name.ar}
                          </h4>
                          {product?.sale_price?.amount && (
                            <h2 className="text-sm text-[#BDBDBD] font-semibold line-through">
                              <span> {product.price.currency} </span>
                              <span> {product.price.amount} </span>
                            </h2>
                          )}
                          <h2
                            className={`${
                              product?.sale_price?.amount
                                ? "text-[#E80000]"
                                : "text-primary"
                            }`}
                          >
                            <span> {product.sale_price.currency} </span>
                            <span>
                              {product?.sale_price?.amount
                                ? product?.sale_price?.amount
                                : product.price.amount}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </label>
                );
              })
            )}
          </div>
          {/* button */}
          <div className="absolute bottom-[20px] left-[50%] -translate-x-[50%]">
            <button
              className="btn btn-primary btn-sm  rounded-3xl btn-wide md:w-[615px]"
              onClick={handleAddToSala}
              disabled={tempProductsAdded.length > 4}
            >
              اضافة المنتجات للسلة
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ProductModal;
