import React, { useState } from 'react';
import minusCollapse from '../../assets/minusCollapse.svg';
import plusCollapse from '../../assets/plusCollapse.svg';
export default function ParentCategory({
  category,
  isSelected,
  tempCategoriesAdded,
  setTempCategoriesAdded,
  openSubCategory,
  openSubSubCategory,
}) {
  const [active, setActive] = useState(openSubCategory);
  const [activeSubCat, setActiveSubCat] = useState(openSubSubCategory);
  const handleChange = (category) => {
    const isSelected = tempCategoriesAdded.some(
      (item) => item.id === category.id
    );

    if (isSelected) {
      setTempCategoriesAdded(
        tempCategoriesAdded.filter((item) => item.id !== category.id)
      );
    } else {
      setTempCategoriesAdded([...tempCategoriesAdded, category]);
    }
  };

  return (
    <label
      className={` custom-radio flex flex-col gap-4 py-2 sm:py-4 cursor-pointer rounded-lg px-3 border-[1px] border-gray-200`}
      htmlFor={category.id}
      key={category.id}
    >
      <div className='flex items-center gap-8'>
        <input
          type='checkbox'
          name='radio-1'
          className='radio radio-primary self-center '
          id={category.id}
          value={category}
          checked={isSelected}
          onClick={() => handleChange(category)}
          readOnly
          disabled={!isSelected && tempCategoriesAdded.length === 2}
        />
        <div
          className={`w-full flex items-center gap-8 ${
            active && 'border-b-2 border-[#EEEEEE] pb-4'
          }`}
        >
          <div className='w-[120px] h-[120px]'>
            <img
              src={category?.image}
              alt='image'
              className='w-full h-full object-contain rounded-2xl'
            />
          </div>
          <div className='flex flex-col gap-2'>
            <h4 className='text-lg font-normal'>{category.names.ar}</h4>

            <span className='text-sm text-gray-400 font-semibold '>
              {category.description}
            </span>
          </div>
          {category?.sub_categories && (
            <button type='button' className='w-6 h-6 ms-auto'>
              <img
                src={`${active ? minusCollapse : plusCollapse}`}
                alt='collapse image'
                className='w-full h-full object-contain self-end'
                onClick={() => setActive((prev) => !prev)}
              />
            </button>
          )}
        </div>
      </div>
      {category?.sub_categories &&
        active &&
        category?.sub_categories?.map((sub_cat) => {
          const isSelectedSubCat = tempCategoriesAdded.some(
            (item) => item.id === sub_cat.id
          );
          return (
            <label
              className={` custom-radio flex flex-col gap-4 py-2  sm:py-4 cursor-pointer rounded-lg px-3 `}
              htmlFor={sub_cat.id}
              key={sub_cat.id}
            >
              <div className='flex gap-8'>
                <input
                  type='checkbox'
                  name='radio-1'
                  className='radio radio-primary self-center '
                  id={sub_cat.id}
                  value={sub_cat}
                  checked={isSelectedSubCat}
                  onClick={() => handleChange(sub_cat)}
                  readOnly
                  disabled={
                    !isSelectedSubCat && tempCategoriesAdded.length === 2
                  }
                />
                <div className='w-full flex gap-8'>
                  <div className='w-[53px] h-[53px]'>
                    <img
                      src={sub_cat?.image}
                      alt='image'
                      className='w-full h-full object-contain rounded-2xl'
                    />
                  </div>
                  <div className='flex flex-col'>
                    <h4 className='text-sm  font-normal'>{sub_cat.names.ar}</h4>
                  </div>
                  {sub_cat?.sub_categories && (
                    <button type='button' className='w-6 h-6 ms-auto'>
                      <img
                        src={`${
                          activeSubCat === sub_cat.id
                            ? minusCollapse
                            : plusCollapse
                        }`}
                        alt='collapse image'
                        className='w-full h-full object-contain self-end'
                        onClick={() =>
                          setActiveSubCat(() =>
                            sub_cat.id === activeSubCat ? '' : sub_cat.id
                          )
                        }
                      />
                    </button>
                  )}
                </div>
              </div>
              {/* third subcat */}
              {sub_cat?.sub_categories &&
                activeSubCat === sub_cat.id &&
                sub_cat?.sub_categories?.map((sub_sub_cat) => {
                  const isSelectedSubSubCat = tempCategoriesAdded.some(
                    (item) => item.id === sub_sub_cat.id
                  );

                  return (
                    <label
                      className={` custom-radio flex justify-between py-2 sm:py-4 cursor-pointer rounded-lg px-3 `}
                      htmlFor={sub_sub_cat.id}
                      key={sub_sub_cat.id}
                    >
                      <div className='flex gap-8'>
                        <input
                          type='checkbox'
                          name='radio-1'
                          className='radio radio-primary self-center '
                          id={sub_sub_cat.id}
                          value={sub_sub_cat}
                          checked={isSelectedSubSubCat}
                          onClick={() => handleChange(sub_sub_cat)}
                          readOnly
                          disabled={
                            !isSelectedSubSubCat &&
                            tempCategoriesAdded.length === 2
                          }
                        />
                        <div className='flex gap-8'>
                          <div className='w-[53px] h-[53px]'>
                            <img
                              src={sub_sub_cat?.image}
                              alt='image'
                              className='w-full h-full object-contain rounded-2xl'
                            />
                          </div>
                          <div className='flex flex-col'>
                            <h4 className='text-sm  font-normal'>
                              {sub_sub_cat.names.ar}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </label>
                  );
                })}
            </label>
          );
        })}
    </label>
  );
}
