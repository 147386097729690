export default function ColorAndTitleInput({
  primaryColor,
  secondaryColor,
  colors,
  oldTitle,
  titles,
  handleTitleChange,
  handleChange,
}) {
  return (
    <div>
      <h3 className='text-primary text-lg sm:text-xl font-semibold my-4 sm:my-8'>
        اختر عنوان وألوان الايقونات في البوب اب
      </h3>
      <div className='flex justify-between flex-col md:flex-row gap-3 items-start flex-wrap'>
        {/* colros */}
        <div className='flex flex-col gap-5 flex-1 '>
          <div>
            <h4 className='text-primary font-normal mb-2'>
              العنوان باللغة العربية
            </h4>
            <input
              type='text'
              id='arabicTitle'
              name='arabicTitle'
              onChange={handleTitleChange}
              value={titles.arabicTitle}
              className='border border-gray-200 max-w-[340px] w-full min-w-[280px] rounded-full p-2 px-3 mb-4'
            />
            <h4 className='text-primary font-normal mb-2'>
              العنوان باللغة الإنجليزية
            </h4>
            <input
              type='text'
              id='englishTitle'
              name='englishTitle'
              onChange={handleTitleChange}
              value={titles.englishTitle}
              className='border border-gray-200 max-w-[340px] w-full min-w-[280px] rounded-full p-2 px-3 mb-4'
            />
            <h4 className='text-primary font-normal mb-4'>اللون الأساسي</h4>
            <div className='flex justify-between max-w-[340px] gap-3'>
              <span className='text-[#9A9A9A] font-normal'>
                {colors.primary_color || primaryColor || '#000'}
              </span>
              <input
                type='color'
                id='colorInputStyle'
                name='primary_color'
                onChange={handleChange}
                value={colors.primary_color || primaryColor || '#000'}
              />
            </div>
          </div>
          <div>
            <h4 className='text-primary font-normal mb-4'>اللون الفرعي</h4>
            <div className='flex justify-between gap-3 max-w-[340px]'>
              <span className='text-[#9A9A9A] font-normal'>
                {colors.secondary_color || secondaryColor || '#000'}
              </span>
              <input
                type='color'
                id='colorInputStyle'
                name='secondary_color'
                onChange={handleChange}
                value={colors.secondary_color || secondaryColor || '#000'}
              />
            </div>
          </div>
        </div>
        <div className='flex-1  bg-[#fff] rounded-2xl p-4 '>
          <h2 className='mb-3 text-[#121212] text-sm'>
            أضف المزيد من المنتجات ذات الصلة
          </h2>
          <div className='flex justify-between flex-wrap gap-3'>
            <div>
              <div className='skeleton w-[96px] h-[96px]'></div>
              <div className='flex justify-between gap-3 mt-3'>
                <div className='skeleton h-4 w-5/6'></div>
                <div
                  className={`skeleton w-4  h-4 rounded-full shrink-0`}
                  style={{
                    backgroundColor:
                      colors?.primary_color || primaryColor || '#000',
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div className='skeleton w-[96px] h-[96px]'></div>
              <div className='flex justify-between gap-3 mt-3'>
                <div className='skeleton h-4 w-5/6'></div>
                <div
                  className={`skeleton w-4  h-4 rounded-full shrink-0`}
                  style={{
                    backgroundColor:
                      colors?.primary_color || primaryColor || '#000',
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div className='skeleton w-[96px] h-[96px]'></div>
              <div className='flex justify-between gap-3 mt-3'>
                <div className='skeleton h-4 w-5/6'></div>
                <div
                  className={`skeleton w-4  h-4 rounded-full shrink-0`}
                  style={{
                    backgroundColor:
                      colors?.primary_color || primaryColor || '#000',
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div className='skeleton w-[96px] h-[96px]'></div>
              <div className='flex justify-between gap-3 mt-3'>
                <div className='skeleton h-4 w-5/6'></div>
                <div
                  className={`skeleton w-4  h-4 rounded-full shrink-0`}
                  style={{
                    backgroundColor:
                      colors?.primary_color || primaryColor || '#000',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* skelton */}
      </div>
    </div>
  );
}
