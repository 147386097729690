import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import Products from './Products';
import Categories from './Categories';
import productsvg from '../assets/icons/iconMenu.svg';
import productGraySvg from '../assets/icons/productSvgGray.svg';
import productActiveSvg from '../assets/icons/productSvgActive.png';
import categorysvg from '../assets/icons/icon-Shop.svg';
import categoryGraysvg from '../assets/icons/shopGray.svg';
import categoryActiveSvg from '../assets/icons/categoryActiveSvg.svg';
import toggleImgOFF from '../assets/icons/toggleOff.svg';
import blockImg from '../assets/blockImg.svg';
import toggleImgOnn from '../assets/icons/toggleOn.svg';
import toggleActive from '../assets/icons/toggleActive.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import customFetch, { checkForUnauthorizedResponse } from '../utils/axios';
import { useDispatch } from 'react-redux';
import SmallLoading from './SmallLoading';
import WebSallaControl from './WebSallaControl';
import AppSallaControl from './AppSallaControl';
export default function SallaInterface({ data, isLoadingProfile }) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = () => {
    data?.store?.subscription_plan?.category_access_rule === false &&
      setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    data?.store?.subscription_plan?.category_access_rule === false &&
      setIsTooltipVisible(false);
  };
  const [active, setActive] = useState('');

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const { mutate: handlePopupSelection, isLoading: isLoadingPopupSelection } =
    useMutation({
      mutationFn: async ({ allowed_display_in_script }) => {
        const { data } = await customFetch.post('/update-content-zid-script', {
          allowed_display_in_script,
        });
        return data;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
      },
      onError: (error) => {
        checkForUnauthorizedResponse(error, dispatch);
      },
    });
  useEffect(() => {
    setActive(data?.store?.subscription_plan?.allowed_display_in_script);
  }, [data?.store?.subscription_plan?.allowed_display_in_script]);
  return (
    <div className='mt-5 sm:mt-10'>
      <div className='grid sm:grid-cols-2 gap-3'>
        <WebSallaControl data={data} isLoadingProfile={isLoadingProfile} />
        <AppSallaControl data={data} isLoadingProfile={isLoadingProfile} />
      </div>
      {/* products and categories */}
      <div className='grid sm:grid-cols-2 mt-5 gap-3 p-3 bg-[#F7F7F8] rounded-xl relative'>
        <button
          className={`flex justify-between items-center py-8 cursor-pointer rounded-lg px-4 border-2  text-primary ${
            data?.store?.subscription_plan?.product_access_rule === false
              ? 'bg-base-100 text-grayColor border-base-100'
              : active === 'products' &&
                'bg-primary border-b-2 border-b-primary border-primary text-white'
          }`}
          onClick={(e) => {
            if (!e.target.classList.contains('toggleScript')) {
              setActive('products');
            }
          }}
          disabled={
            data?.store?.subscription_plan?.product_access_rule === false
          }
        >
          {data?.store?.subscription_plan?.product_access_rule !== false &&
          isLoadingPopupSelection ? (
            <SmallLoading txtColor='text-grayColor' />
          ) : data?.store?.subscription_plan?.allowed_display_in_script ===
            'products' ? (
            <button
              onClick={() =>
                handlePopupSelection({
                  allowed_display_in_script: 'products',
                })
              }
              disabled={isLoadingProfile || isLoadingPopupSelection}
              className={`${
                isLoadingPopupSelection && 'cursor-not-allowed'
              } toggleScript `}
            >
              <img
                src={`${active === 'products' ? toggleActive : toggleImgOnn}`}
                alt='toggole on'
                className='toggleScript'
              />
            </button>
          ) : (
            <button
              onClick={() =>
                handlePopupSelection({
                  id: data?.store?.subscription_plan?.id,
                  allowed_display_in_script: 'products',
                })
              }
              disabled={isLoadingProfile || isLoadingPopupSelection}
              className={`${
                isLoadingPopupSelection && 'cursor-not-allowed'
              } toggleScript `}
            >
              <img
                src={toggleImgOFF}
                alt='toggole on'
                className='toggleScript'
              />
            </button>
          )}

          <div className='flex gap-2 items-center '>
            <img
              src={`${
                data?.store?.subscription_plan?.product_access_rule === false
                  ? productGraySvg
                  : active === 'products'
                  ? productActiveSvg
                  : productsvg
              }`}
              alt='category icon'
            />{' '}
            <span className='font-bold'>المنتجات</span>
          </div>
          <div>
            {data?.store?.subscription_plan?.allowed_display_in_script ===
              'products' && (
              <div className='avatar placeholder'>
                <div
                  className={`${
                    active === 'products'
                      ? 'bg-base-100 text-primary'
                      : 'bg-primary text-white'
                  }  rounded-full w-6`}
                >
                  <span className='text-xs'>
                    <FaCheck />
                  </span>
                </div>
              </div>
            )}
          </div>
        </button>
        <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          <button
            className={`w-full flex justify-between items-center py-8 cursor-pointer rounded-lg px-4 border-2  text-primary relative  overflow-hidden ${
              data?.store?.subscription_plan?.category_access_rule === false
                ? 'bg-base-100 text-grayColor border-base-100'
                : active === 'categories' &&
                  'bg-primary border-b-2 border-b-primary border-primary text-white'
            }`}
            disabled={
              data?.store?.subscription_plan?.category_access_rule === false
            }
            onClick={(e) => {
              if (!e.target.classList.contains('toggleScript')) {
                setActive('categories');
              }
            }}
          >
            {data?.store?.subscription_plan?.category_access_rule === false && (
              <>
                <div class='triangle-up'></div>
                <img
                  src={blockImg}
                  alt='blokkimg'
                  className='absolute left-[12px] top-[18px]'
                />
              </>
            )}
            {data?.store?.subscription_plan?.category_access_rule !== false &&
            isLoadingPopupSelection ? (
              <SmallLoading txtColor='text-grayColor' />
            ) : data?.store?.subscription_plan?.allowed_display_in_script ===
              'categories' ? (
              <button
                onClick={() =>
                  handlePopupSelection({
                    id: data?.store?.subscription_plan?.id,
                    allowed_display_in_script: 'categories',
                  })
                }
                disabled={isLoadingProfile || isLoadingPopupSelection}
                className={`${
                  isLoadingPopupSelection && 'cursor-not-allowed'
                } toggleScript `}
              >
                <img
                  src={`${
                    active === 'categories' ? toggleActive : toggleImgOnn
                  }`}
                  alt='toggole on'
                  className='toggleScript'
                />
              </button>
            ) : (
              <button
                onClick={() =>
                  handlePopupSelection({
                    id: data?.store?.subscription_plan?.id,
                    allowed_display_in_script: 'categories',
                  })
                }
                disabled={isLoadingProfile || isLoadingPopupSelection}
                className={`${
                  isLoadingPopupSelection && 'cursor-not-allowed'
                }  toggleScript `}
              >
                <img
                  src={toggleImgOFF}
                  alt='toggole on'
                  className='toggleScript'
                />
              </button>
            )}
            <div className='flex gap-2 items-center '>
              <img
                src={`${
                  data?.store?.subscription_plan?.category_access_rule === false
                    ? categoryGraysvg
                    : active === 'categories'
                    ? categoryActiveSvg
                    : categorysvg
                }`}
                alt='category icon'
              />
              <span
                className={`font-bold ${
                  data?.store?.subscription_plan?.category_access_rule ===
                    false && 'text-grayColor'
                }`}
              >
                تصنيفات مع منتجاتها
              </span>
            </div>
            <div>
              {data?.store?.subscription_plan?.allowed_display_in_script ===
                'categories' && (
                <div className='avatar placeholder'>
                  <div
                    className={`${
                      active === 'categories'
                        ? 'bg-base-100 text-primary'
                        : 'bg-primary text-white'
                    }  rounded-full w-6`}
                  >
                    <span className='text-xs'>
                      <FaCheck />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </button>
        </div>
        {isTooltipVisible && (
          <div
            className='absolute -left-[55px] -top-[56px] bg-base-100 max-w-[200px] text-[#707070] px-2 py-2 rounded-md shadow-lg z-10 text-center'
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
          >
            <div className='tooltip-content relative'>
              عليك{' '}
              <a
                class='font-bold underline underline-offset-4'
                href='https://web.zid.sa/market/app/3083'
                target='_blank'
              >
                ترقية الباقة
              </a>{' '}
              لتفعيل هذة الخاصية
              <div className='tooltip-arrow' />
            </div>
          </div>
        )}
      </div>
      {active === 'products' ? (
        <Products profileData={data} />
      ) : (
        <Categories profileData={data} />
      )}
    </div>
  );
}
