import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { IoMdClose } from 'react-icons/io';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
// image
import addToCart from '../../assets/add-to-cart-3046 1.svg';
import arrowLeft from '../../assets/Arrow - Left.svg';
import arrowRight from '../../assets/Arrow - Right.svg';
import CartMobPopup from '../../assets/cartmobilePop.svg';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
export default function MobileProductPreview({
  openMobileProductPreview,
  handleToggleMobileProductPreview,
  myProducts,
}) {
  const modalClass = cn({
    'modal modal-middle ': true,
    'modal-open': openMobileProductPreview,
  });
  let totalPrice = 0;
  return (
    <dialog id='product_modal' className={`${modalClass} `}>
      <div className='mockup-phone w-[360px] p-0 relative overflow-visible'>
        <div className='absolute -left-[51px] -top-[24px]'>
          <button
            type='button'
            className='w-14 h-14 flex justify-center items-center rounded-full bg-base-100  '
            onClick={handleToggleMobileProductPreview}
          >
            <IoMdClose className='font-bold text-black text-lg' />
          </button>
        </div>
        {/* <div className='camera'></div> */}
        <div className='display !mt-0'>
          <div className='artboard artboard-demo  w-full pt-[17rem]'>
            <div className={`modal-box w-full  p-0 overflow-hidden`}>
              <form
                method='dialog'
                className='flex justify-between items-center  py-2 px-5'
              >
                <h3 className='text-base sm:text-lg font-semibold '>
                  أضف المزيد من المنتجات
                </h3>
                <button onClick={handleToggleMobileProductPreview}>
                  <IoMdClose className='font-bold text-black text-lg' />
                </button>
              </form>
              <div className=' h-full w-full flex  pr-5 py-3'>
                <Swiper
                  slidesPerView='2.2'
                  spaceBetween='20'
                  speed={1000}
                  navigation={{
                    nextEl: '.custom-next',
                    prevEl: '.custom-prev',
                  }}
                  style={{ height: '335px' }} // Set a fixed height for the Swiper container
                >
                  {/* <div className=' absolute inset-0 flex items-center justify-between z-10'>
              <div className='custom-prev cursor-pointer'>
                <img src={arrowRight} alt='' />
              </div>
              <div className='custom-next cursor-pointer '>
                <img src={arrowLeft} alt='' />
              </div>
            </div> */}
                  {myProducts?.map((product, index) => {
                    totalPrice += product?.sale_price?.amount
                      ? product.sale_price.amount
                      : product.price.amount;

                    return (
                      <SwiperSlide key={product.id}>
                        <div className='flex flex-col gap-3 max-w-[256px]  h-full items-stretch  '>
                          <div className='max-w-[256px]  rounded-xl h-[160px] flex justify-center items-center'>
                            <img
                              src={product.main_image}
                              alt='image'
                              className='max-h-full object-contain'
                            />
                          </div>
                          <h3 className='text-[#121212] font-light product-title'>
                            {product.name.ar}
                          </h3>
                          <div className='flex  flex-col items-start gap-2  mt-auto'>
                            {/* salary */}
                            {product?.sale_price?.amount && (
                              <h2 className='text-sm text-[#BDBDBD] font-semibold line-through'>
                                <span> {product.price.currency} </span>
                                <span> {product.price.amount} </span>
                              </h2>
                            )}
                            <h2
                              className={`${
                                product?.sale_price?.amount
                                  ? 'text-[#E80000] '
                                  : 'text-primary'
                              } `}
                            >
                              <span> {product.sale_price.currency} </span>
                              <span>
                                {product?.sale_price?.amount
                                  ? product?.sale_price?.amount
                                  : product.price.amount}
                              </span>
                            </h2>
                          </div>
                          {/* icon */}
                          {/* <div className='avatar placeholder cursor-pointer'>
                    <div className='bg-base-100 border-primary border-[1px] rounded-full w-10 p-2'>
                      <img src={addToCart} className='w-[24px] h-[24px]' />
                    </div>
                  </div> */}
                          <button className='btn btn-primary  mt-auto'>
                            <img src={CartMobPopup} alt='imageCart' />
                            <span className='text-xs font-normal'>
                              {' '}
                              أضف للسلة
                            </span>
                          </button>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              <div className='flex justify-between gap-2 bg-[#EFEFFB] px-5 py-5 mb-3'>
                <div>
                  <h2 className='text-primary'>الإجمالي</h2>
                  <span className='block text-primary'>{totalPrice} ر.س</span>
                </div>
                <button className='btn btn-primary rounded-3xl text-xs font-bold'>
                  التقدم لاتمام الطلب
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
}
