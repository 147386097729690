import React, { useState } from "react";
import cn from "classnames";
import { FaSearch } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { useDispatch } from "react-redux";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import ParentCategory from "./ParentCategory";

const CategoryModal = ({
  open,
  handleToggle,
  myCategories,
  setMyCategories,
}) => {
  const modalClass = cn({
    "modal modal-middle ": true,
    "modal-open": open,
  });
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [categoriesFromSearch, setCategoriesFromSearch] = useState([]);

  const { isLoading: isLoadingFetchCategories, data: categories } = useQuery({
    queryKey: ["allCategories"],
    queryFn: async () => {
      const { data } = await customFetch("/get-categories");
      return data.data;
    },
    onError: (error) => {
      checkForUnauthorizedResponse(error, dispatch);
    },
  });

  const [tempCategoriesAdded, setTempCategoriesAdded] = useState(myCategories);

  const handleAddToSala = () => {
    if (tempCategoriesAdded.length === 0) {
      return;
    }
    setMyCategories(tempCategoriesAdded);
    handleToggle();
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    performSearch(event.target.value);
  };

  const performSearch = (query) => {
    const filteredData = categories.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    setCategoriesFromSearch(filteredData);
  };

  const sortSelectedCategories = (categories, selectedCategories) => {
    if (!categories) {
      return [];
    }
    const unselected = categories.filter((item) => {
      return !selectedCategories.some((selected) => selected.id === item.id);
    });
    const selected = categories.filter((item) => {
      return selectedCategories.some((selected) => selected.id === item.id);
    });
    const missingSelected = selectedCategories.filter((selected) => {
      return !categories.some((item) => item.id === selected.id);
    });
    return [...selected, ...missingSelected, ...unselected];
  };

  const sortedCategories = search
    ? sortSelectedCategories(categoriesFromSearch, tempCategoriesAdded)
    : sortSelectedCategories(categories, tempCategoriesAdded);

  return (
    <dialog id="product_modal" className={`${modalClass}`}>
      <div className="modal-box max-w-[82rem] h-[82rem] p-0 overflow-hidden">
        <form
          method="dialog"
          className="flex justify-between items-center bg-[#F5F7F7] py-2 px-5"
        >
          <h3 className="text-base sm:text-lg font-semibold">
            اضافة تصنيف في واجة السلة
          </h3>
          <button onClick={handleToggle}>
            <IoMdClose className="font-bold text-black text-lg" />
          </button>
        </form>
        <div className="flex flex-col gap-3 mt-3 sm:mt-5 px-5 sm:px-6">
          <div className="relative">
            <div className="form-control">
              <input
                type="text"
                placeholder="بحث"
                className="input input-bordered input-sm sm:input-md rounded-3xl"
                name="search"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            <FaSearch
              type="submit"
              className="text-lg text-primary absolute top-2 sm:top-4 left-5"
            />
          </div>
          {tempCategoriesAdded.length === 2 && (
            <div className="flex gap-2 items-center mt-3 text-[#EAA900] text-base sm:text-lg font-bold">
              <IoAlertCircleOutline className="text-2xl" />
              <span>لقد وصلت للحد الاقصى لإضافة التصنيفات في السلة</span>
            </div>
          )}
          <div className="flex flex-col gap-3 pb-20 h-[75vh] overflow-y-auto">
            {isLoadingFetchCategories ? (
              <span className="loading loading-dots text-primary loading-lg mx-auto block"></span>
            ) : sortedCategories?.length === 0 ? (
              <h3 className="text-center text-lg font-semibold">
                لا يوجد تصنيفات
              </h3>
            ) : (
              sortedCategories?.map((category) => {
                const isSelected = tempCategoriesAdded.some(
                  (item) => item.id === category.id
                );
                const openSubCategory =
                  category?.sub_categories?.length > 0 &&
                  category.sub_categories.find((subcat) =>
                    tempCategoriesAdded.some((xObj) => xObj.id === subcat.id)
                  );
                const openSubSubCategory =
                  category?.sub_categories?.length > 0 &&
                  category.sub_categories.find((subcat) => {
                    return (
                      subcat?.sub_categories?.length > 0 &&
                      subcat.sub_categories.find((subSubcat) =>
                        tempCategoriesAdded.some(
                          (xObj) => xObj.id === subSubcat.id
                        )
                      )
                    );
                  });
                return (
                  <ParentCategory
                    category={category}
                    isSelected={isSelected}
                    tempCategoriesAdded={tempCategoriesAdded}
                    setTempCategoriesAdded={setTempCategoriesAdded}
                    openSubCategory={
                      openSubCategory?.id
                        ? true
                        : openSubSubCategory?.id
                        ? true
                        : false
                    }
                    openSubSubCategory={openSubSubCategory?.id}
                  />
                );
              })
            )}
          </div>
          <div className="absolute bottom-[20px] left-[50%] -translate-x-[50%]">
            <button
              className="btn btn-primary btn-sm rounded-3xl btn-wide md:w-[615px]"
              onClick={handleAddToSala}
              disabled={tempCategoriesAdded.length > 2}
            >
              اضافة التصنيفات للسلة
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default CategoryModal;
