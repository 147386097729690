import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { IoMdClose } from 'react-icons/io';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
// image
import addToCart from '../../assets/add-to-cart-3046 1.svg';
import arrowLeft from '../../assets/Arrow - Left.svg';
import arrowRight from '../../assets/Arrow - Right.svg';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { useQuery } from '@tanstack/react-query';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Loading from '../Loading';
const CategoryPreview = ({
  openCategoryPreview,
  handleToggleCategoryPreview,
  myCategories,
}) => {
  const [tab, setTab] = useState(myCategories[0]?.id);
  const dispatch = useDispatch();
  // const [myProducts, setMyProducts] = useState([]);
  // const getProducts = async () => {
  //   try {
  //     const { data } = await customFetch(`/get-products/${tab}`);
  //     setMyProducts(data.data);
  //   } catch (error) {
  //     checkForUnauthorizedResponse({ error, dispatch });
  //   }
  // };
  const { isLoading: isLoadingFetchProducts, data: myProducts } = useQuery({
    queryKey: ['allProducts', tab],
    queryFn: async () => {
      const { data } = await customFetch(`/get-products/${tab}`);
      return data.data;
    },
    onError: (error) => {
      checkForUnauthorizedResponse(error, dispatch);
    },
  });

  // useEffect(() => {
  //   getProducts();
  // }, [tab]);

  const modalClass = cn({
    'modal modal-middle ': true,
    'modal-open': openCategoryPreview,
  });
  const adjstWidth = () => {
    const lenSlides = Array.from(
      { length: toGetSlidePerview() },
      (_, index) => {
        return index + 1;
      }
    );
    let totalWidth = 0;
    if (window.innerWidth < 576) {
      return 'max-w-[90vw]';
    } else if (
      window.innerWidth > 1024 &&
      (myProducts?.length === 1 || myProducts?.length === 2)
    ) {
      return 'max-w-[600px]';
    } else if (window.innerWidth > 1024 && myProducts?.length === 3) {
      return 'max-w-[900px]';
    } else if (window.innerWidth > 1024 && myProducts?.length >= 4) {
      return 'max-w-[1200px]';
    }
  };
  const toGetSlidePerview = () => {
    if (window.innerWidth < '1024') {
      return myProducts?.length < 2 ? myProducts?.length : '2';
    } else {
      return myProducts?.length < 4 ? myProducts?.length : '4';
    }
  };
  // useEffect(() => {
  //   adjstWidth();

  // }, [myProducts]);
  if (isLoadingFetchProducts) {
    return (
      <dialog id='product_modal' className={`${modalClass} `}>
        <div className={`modal-box  p-0 overflow-hidden`}>
          <form
            method='dialog'
            className='flex justify-between items-center bg-[#F5F7F7] py-2 px-5'
          >
            <h3 className='text-base sm:text-lg font-semibold '>
              اضف المزيد من المنتجات ذات الصلة
            </h3>
            <button onClick={handleToggleCategoryPreview}>
              <IoMdClose className='font-bold text-black text-lg' />
            </button>
          </form>
          <Loading />
        </div>
      </dialog>
    );
  }
  return (
    <dialog id='product_modal' className={`${modalClass} `}>
      <div className={`modal-box  ${adjstWidth()} p-0 overflow-hidden`}>
        <form
          method='dialog'
          className='flex justify-between items-center bg-[#F5F7F7] py-2 px-5'
        >
          <h3 className='text-base sm:text-lg font-semibold '>
            اضف المزيد من المنتجات ذات الصلة
          </h3>
          <button onClick={handleToggleCategoryPreview}>
            <IoMdClose className='font-bold text-black text-lg' />
          </button>
        </form>
        <div className='flex justify-around items-center pt-5 border-b-[1px] border-[#ccc]'>
          {myCategories?.map((cat) => (
            <button
              className={`${
                tab === cat.id
                  ? 'text-primary border-b-2 border-primary'
                  : 'text-[#9a9a9a] font-bold '
              } text-[]`}
              key={cat.id}
              onClick={() => setTab(cat.id)}
            >
              {cat.name}
            </button>
          ))}
        </div>
        <div className=' h-full w-full flex flex-col justify-center items-center px-5 sm:px-10 py-10'>
          <Swiper
            slidesPerView={toGetSlidePerview()}
            spaceBetween={10}
            speed={1000}
            breakpoints={{
              1024: {
                slidesPerView: toGetSlidePerview(),
                spaceBetween: 20,
              },
            }}
            navigation={{
              nextEl: '.custom-next',
              prevEl: '.custom-prev',
            }}
            modules={[Autoplay, Navigation]}
            className='w-full'
          >
            <div className=' absolute inset-0 flex items-center justify-between z-10 -top-[158px]'>
              <div className='custom-prev cursor-pointer'>
                <img src={arrowRight} alt='' />
              </div>
              <div className='custom-next cursor-pointer '>
                <img src={arrowLeft} alt='' />
              </div>
            </div>
            {myProducts?.map((product, index) => (
              <SwiperSlide
                key={product.id}
                className='!flex flex-col justify-center items-center'
              >
                <div className='flex flex-col gap-3 max-w-[256px] w-full '>
                  <div className=' max-w-[256px] h-48 sm:h-64 flex justify-center items-center  rounded-xl'>
                    <img
                      src={product.main_image}
                      alt='image'
                      className='max-h-full object-contain'
                    />
                  </div>
                  <h3 className='text-[#121212] font-light h-[40px] overflow-hidden leading-[20px]'>
                    {product.name.ar}
                  </h3>
                  <div className='flex items-center justify-between'>
                    {/* salary */}
                    <h2
                      className={`${
                        product?.sale_price?.amount
                          ? 'text-[#E80000] '
                          : 'text-primary'
                      } `}
                    >
                      <span> {product.sale_price.currency} </span>
                      <span>
                        {product?.sale_price?.amount
                          ? product?.sale_price?.amount
                          : product.price.amount}
                      </span>
                    </h2>
                    {product?.sale_price?.amount && (
                      <h2 className='text-sm text-[#BDBDBD] font-semibold line-through'>
                        <span> {product.price.currency} </span>
                        <span> {product.price.amount} </span>
                      </h2>
                    )}
                    {/* icon */}
                    <div className='avatar placeholder cursor-pointer'>
                      <div className='bg-base-100 border-primary border-[1px] rounded-full w-10 p-2'>
                        <img src={addToCart} className='w-[24px] h-[24px]' />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className='flex justify-center mt-5'>
              <div className='flex justify-between gap-10 rounded-3xl bg-[#EFEFFB]'>
                <div className='flex items-center gap-3 ps-4 '>
                  <span className='font-normal text-primary'>الاجمالي :</span>
                  <span className='text-2xl font-bold text-primary'>
                    <span> 4,600</span>
                    <span className='ps-1'>ر.س</span>
                  </span>
                </div>
                <button className='btn btn-primary rounded-3xl btn-wide'>
                  التقدم لاتمام الطلب
                </button>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </dialog>
  );
};

export default CategoryPreview;
// autoplay={{
//   delay: 3000,
//   disableOnInteraction: false,
// }}
