/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import infoImg from '../assets/Info.svg';
import toggleImgOnn from '../assets/icons/toggleOn.svg';

import toggleImgOFF from '../assets/icons/toggleOff.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import customFetch, { checkForUnauthorizedResponse } from '../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import SmallLoading from './SmallLoading';
import { logDOM } from '@testing-library/react';
export default function AppSallaControl({ isLoadingProfile, data }) {
  const { user } = useSelector((user) => user);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const { mutate: handleScriptStatus, isLoading: isLoadingScriptStatus } =
    useMutation({
      mutationFn: async (status) => {
        const { data } = await customFetch.post('/update-mobile-popup', {
          status,
        });
        return data;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
      },
      onError: (error) => {
        checkForUnauthorizedResponse(error, dispatch);
      },
    });
  const showTooltip = () => {
    if (!data?.store?.subscription_plan?.mobile_access_rule)
      setIsTooltipVisible(true);
  };
  const hideTooltip = () => {
    if (!data?.store?.subscription_plan?.mobile_access_rule)
      setIsTooltipVisible(false);
  };
  return (
    <div
      className='relative'
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      <div
        className='flex items-center gap-2'
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <h3 className='text-primary text-lg sm:text-xl font-semibold'>
          قسم واجهة السلة للتطبيق
        </h3>
        {isLoadingScriptStatus || isLoadingProfile ? (
          <SmallLoading />
        ) : data?.store?.mobile_popup_status ? (
          <button
            onClick={() => {
              if (data?.store?.subscription_plan?.mobile_access_rule !== true)
                return;
              handleScriptStatus(false);
            }}
            disabled={
              data?.store?.subscription_plan?.mobile_access_rule !== true
                ? true
                : false
            }
            className={`${
              data?.store?.subscription_plan?.mobile_access_rule !== true &&
              'cursor-not-allowed'
            }`}
          >
            {data?.store?.subscription_plan?.mobile_access_rule !== true ? (
              <img
                src={toggleImgOFF}
                alt='toggole on'
                className='toggleScript'
              />
            ) : (
              <img
                src={toggleImgOnn}
                alt='toggole on'
                className='toggleScript'
              />
            )}
          </button>
        ) : (
          <button
            onClick={() => {
              if (data?.store?.subscription_plan?.mobile_access_rule !== true)
                return;
              handleScriptStatus(true);
            }}
            disabled={
              data?.store?.subscription_plan?.mobile_access_rule !== true
                ? true
                : false
            }
            className={`${
              data?.store?.subscription_plan?.mobile_access_rule !== true &&
              'cursor-not-allowed'
            }`}
          >
            <img
              src={toggleImgOFF}
              alt='toggole off'
              className='toggleScript'
            />
          </button>
        )}
        {/* <input
          type='checkbox'
          className='toggle toggle-primary bg-[#707070] h-5'
          // checked={a === 'products'}
        /> */}
      </div>
      {isTooltipVisible && (
        <div
          className='absolute right-[136px] -top-[64px] bg-base-100 max-w-[200px] text-[#707070] px-2 py-2 rounded-md shadow-lg z-10 text-center'
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <div className='tooltip-content relative'>
            عليك{' '}
            <a
              class='font-bold underline underline-offset-4'
              href='https://web.zid.sa/market/app/3083'
              target='_blank'
            >
              ترقية الباقة
            </a>{' '}
            لتفعيل هذة الخاصية
            <div className='tooltip-arrow' />
          </div>
        </div>
      )}
    </div>
  );
}
