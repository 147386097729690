import React, { useState } from 'react';
import cn from 'classnames';

import { IoMdClose } from 'react-icons/io';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// image

import CartMobPopup from '../../assets/cartmobilePop.svg';

// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/navigation';
import { useQuery } from '@tanstack/react-query';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Loading from '../Loading';
const MobileCategoryPreview = ({
  openMobileCategoryPreview: openCategoryPreview,
  handleToggleMobileCategoryPreview: handleToggleCategoryPreview,
  myCategories,
}) => {
  const [tab, setTab] = useState(myCategories[0]?.id);
  let totalPrice = 0;
  const dispatch = useDispatch();
  const { isLoading: isLoadingFetchProducts, data: myProducts } = useQuery({
    queryKey: ['allProducts', tab],
    queryFn: async () => {
      const { data } = await customFetch(`/get-products/${tab}`);
      return data.data;
    },
    onError: (error) => {
      checkForUnauthorizedResponse(error, dispatch);
    },
  });

  const modalClass = cn({
    'modal modal-middle ': true,
    'modal-open': openCategoryPreview,
  });

  if (isLoadingFetchProducts) {
    return (
      <dialog id='product_modal' className={`${modalClass} `}>
        <div className={`modal-box  p-0 overflow-hidden`}>
          <form
            method='dialog'
            className='flex justify-between items-center bg-[#F5F7F7] py-2 px-5'
          >
            <h3 className='text-base sm:text-lg font-semibold '>
              اضف المزيد من المنتجات ذات الصلة
            </h3>
            <button onClick={handleToggleCategoryPreview}>
              <IoMdClose className='font-bold text-black text-lg' />
            </button>
          </form>
          <Loading />
        </div>
      </dialog>
    );
  }
  return (
    <dialog id='product_modal' className={`${modalClass} `}>
      <div className='mockup-phone w-[360px] p-0 relative overflow-visible'>
        <div className='absolute -left-[70px] -top-[1px]'>
          <button
            type='button'
            className='w-14 h-14 flex justify-center items-center rounded-full bg-base-100 '
            onClick={handleToggleCategoryPreview}
          >
            <IoMdClose className='font-bold text-black text-lg' />
          </button>
        </div>
        {/* <div className='camera'></div> */}
        <div className='display !mt-0'>
          <div className='artboard artboard-demo  w-full pt-[14rem]'>
            <div className={`modal-box w-full  p-0 overflow-hidden`}>
              <form
                method='dialog'
                className='flex justify-between items-center bg-[#F5F7F7] py-2 px-5'
              >
                <h3 className='text-base sm:text-lg font-semibold '>
                  اضف المزيد من المنتجات ذات الصلة
                </h3>
                <button onClick={handleToggleCategoryPreview}>
                  <IoMdClose className='font-bold text-black text-lg' />
                </button>
              </form>
              <div className='flex justify-around items-center pt-5 border-b-[1px] border-[#ccc]'>
                {myCategories?.map((cat) => (
                  <button
                    className={`${
                      tab === cat.id
                        ? 'text-primary border-b-2 border-primary'
                        : 'text-[#9a9a9a] font-bold '
                    } text-[]`}
                    key={cat.id}
                    onClick={() => setTab(cat.id)}
                  >
                    {cat.name}
                  </button>
                ))}
              </div>
              <div className=' h-full w-full flex flex-col justify-center items-center pr-5  py-5'>
                <Swiper
                  slidesPerView={myProducts.length >= 2 ? '2.3' : '1'}
                  spaceBetween={10}
                  speed={1000}
                  modules={[Autoplay]}
                  className='w-full'
                  style={{ height: '335px' }} // Set a fixed height for the Swiper container
                >
                  {/* arrows */}
                  {/* <div className=' absolute inset-0 flex items-center justify-between z-10'>
              <div className='custom-prev cursor-pointer'>
                <img src={arrowRight} alt='' />
              </div>
              <div className='custom-next cursor-pointer '>
                <img src={arrowLeft} alt='' />
              </div>
            </div> */}
                  {myProducts?.map((product, index) => {
                    totalPrice += product?.sale_price?.amount
                      ? product.sale_price.amount
                      : product.price.amount;
                    return (
                      <SwiperSlide key={product.id}>
                        <div className='flex flex-col gap-3 max-w-[256px] h-full items-stretch '>
                          <div className='max-w-[256px]  rounded-xl h-[160px] flex justify-center items-center'>
                            <img
                              src={product.main_image}
                              alt='image'
                              className='max-h-full object-contain'
                            />
                          </div>
                          <h3 className='text-[#121212] font-light product-title'>
                            {product.name.ar}
                          </h3>
                          <div className='flex flex-col items-start gap-2 mt-auto'>
                            {product?.sale_price?.amount && (
                              <h2 className='text-sm text-[#BDBDBD] font-semibold line-through'>
                                <span> {product.price.currency} </span>
                                <span> {product.price.amount} </span>
                              </h2>
                            )}
                            {/* salary */}
                            <h2
                              className={`${
                                product?.sale_price?.amount
                                  ? 'text-[#E80000] '
                                  : 'text-primary'
                              } `}
                            >
                              <span> {product.sale_price.currency} </span>
                              <span>
                                {product?.sale_price?.amount
                                  ? product?.sale_price?.amount
                                  : product.price.amount}
                              </span>
                            </h2>
                          </div>
                          {/* icon */}
                          {/* <div className='avatar placeholder cursor-pointer'>
                    <div className='bg-base-100 border-primary border-[1px] rounded-full w-10 p-2'>
                      <img src={addToCart} className='w-[24px] h-[24px]' />
                    </div>
                  </div> */}
                          <button className='btn btn-primary mt-auto'>
                            <img src={CartMobPopup} alt='imageCart' />
                            <span className='text-xs font-normal'>
                              {' '}
                              أضف للسلة
                            </span>
                          </button>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              <div className='flex justify-between gap-2 bg-[#EFEFFB] px-5  py-5 mb-3'>
                <div>
                  <h2 className='text-primary'>الإجمالي</h2>
                  <span className='block text-primary'>{totalPrice} ر.س</span>
                </div>
                <button className='btn btn-primary rounded-3xl text-xs font-bold'>
                  التقدم لاتمام الطلب
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default MobileCategoryPreview;
// autoplay={{
//   delay: 3000,
//   disableOnInteraction: false,
// }}
